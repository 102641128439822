
import { computed, defineComponent, ref } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

import UserForm from '@/views/admin/users/components/UserForm.vue'

import { useNotify } from '@/store'

import { usersApi } from '@/api'

import { UserUpdate } from '@/api/types/user'

import { ValidationForm } from '@/utils/types/validation'

export default defineComponent({
  name: 'AdminAddUser',
  components: {
    UserForm,
  },
  setup(props, { root, emit }) {
    const { createUser, isLoading } = usersApi.useCreateUser()

    const { addNotification } = useNotify()

    const addUserForm = ref<ValidationForm | null>(null)

    const defaultLocale = computed((): string => root.$store.state.locale.defaultLocale)

    const editUser = ref<UserUpdate>({
      enabled: true,
      locale: defaultLocale.value,
    })

    const addUser = () => {
      if (addUserForm.value?.validate()) {
        createUser(editUser.value)
          .then((newUser) => {
            addNotification({
              type: 'success',
              text: root.$t('users.create.success', {
                name: `${newUser.firstName} ${newUser.lastName}`,
              }) as string,
            })
            emit('user-added', newUser)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('users.create.error', {
              name: `${editUser.value.firstName} ${editUser.value.lastName}`,
            })

            // check if 409 - conflict
            if (error.response?.status === 409) {
              addNotification({
                type: 'error',
                text: root.$t('misc.userAlreadyExists') as string,
              })
            } else {
              throw error
            }
          })
      }
    }

    return {
      icons: { mdiCheck },
      addUserForm,
      editUser,
      addUser,
      isLoading,
    }
  },
})
